
import { mapMutations, mapState, mapGetters } from 'vuex'
import LoginViaPhone from '~/components/Login/LoginViaPhone.vue'
export default {
  // middleware: ['guest'],
  components: {
    LoginViaPhone
  },
  data () {
    return {
      error_message: '',
      email: '',
      password: '',
      name: '',
      phone: '',
      accepted: 'false',
      rememberme: 'true',
      showPassword: false,
      authenType: 'signin',
      cookieAccept: true
    }
  },
  computed: {
    ...mapState(['userData', 'lastLoginFail', 'lastLoginChanel']),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      isAuthenticating: 'isAuthenticating',
      authUser: 'firebaseUser/getAuthUser'
    })
  },
  mounted () {
    this.setLastLoginFail(false)
    this.setLastLoginChannel('')
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: 'Login Page'
      }
    })

    // if (this.$route.query.redirect) {
    //   // console.log('-- loginVP 1 --')
    //   localStorage.setItem('redirect_url', this.$route.query.redirect || '')
    // } else {
    //   // console.log('-- loginVP 2 --')
    //   localStorage.removeItem('redirect_url')
    // }

    const backUrl = localStorage.getItem('back_url')
    // console.log('authUser', this.authUser)
    // console.log('isAuthenticated', this.isAuthenticated)
    // console.log('userData', this.userData)
    // console.log('login-via-phone', this.isAuthenticated, this.userData)
    if ((this.isAuthenticated && this.userData)) {
    // if (this.authUser || (this.isAuthenticated && this.userData)) {
      if (backUrl) {
        // console.log('-- loginVP 3 --')
        localStorage.removeItem('back_url')
        this.$router.push(backUrl)
      } else {
        // console.log('-- loginVP 4 --')
        this.$router.push('/')
      }
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setLastLoginFail', 'setLastLoginChannel'])
  }
}
