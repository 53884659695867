
// import { BIconArrowLeft, BIconArrowRight } from 'bootstrap-vue' // BIconStarFill
import { mapState } from 'vuex'
import ShareUrl from '~/components/ShareUrl.vue'
// BIconArrowLeft,
// BIconArrowRight
export default {
  components: {
    ShareUrl
  },
  async asyncData ({ route, $axios, app, $replaceSEO }) {
    const seo = await $axios.get('sitemaps/articles_detail')
    const data = await $axios.$get('/articles/' + route.params.id)
    const article = data.data
    if (!article.img) {
      article.img = require('~/static/images/articles/default.jpg')
    }
    const splitDate = data.data.created_at.split(' ')[0].split('-')
    return {
      article,
      pageUrl: process.env.BASE_URL + route.fullPath,
      createdDateFormatted: app.$dateFns.format(
        new Date(splitDate[0], splitDate[1] - 1, splitDate[2]),
        'dd MMMM yyyy'
      ),
      categories: article.categories,
      seo: $replaceSEO(seo.data.data, article.seo_title, article.id, null, null, null, article.seo_description, article.seo_keyword)
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    // console.log(window.$('.read-more').readMore)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
    setTimeout(() => {
      window.$('.read-more').readMore({ previewHeight: 300 })
    }, 1500)

    if (this.article) {
      const promotions = []
      promotions.push({
        id: '',
        name: String(this.article.title),
        position: 1
      })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'promoViewed',
        ecommerce: {
          promoView: {
            promotions
          }
        }
      })
    }
  },
  methods: {
    // openExternalWindow () {
    //   window.liff.openWindow({
    //     url: 'https://www.locopack.co/th/?openExternalBrowser=1',
    //     external: true
    //   })
    // },
    promoClicked () {
      if (this.article) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'promoClicked',
          ecommerce: {
            promoClick: {
              promotions: [{
                id: '',
                name: String(this.article.title),
                position: 1
              }]
            }
          }
        })
        this.$router.push(this.localeLocation({ name: 'products' }))
      }
    },
    formatDate (date) {
      if (date) {
        const splitDate = date.split(' ')[0].split('-')
        return this.$dateFns.format(
          new Date(splitDate[0], splitDate[1] - 1, splitDate[2]),
          'dd MMMM yyyy'
        )
      }
    },
    rmTag (text) {
      return text.replace(/(<([^>]+)>)/ig, '')
    }
  },
  head () {
    if (this.article) {
      const metaTitle = this.seo.title
      const metaImage = this.article.img
      return {
        script: [
          // {
          //   hid: 'jquery',
          //   src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js',
          //   type: 'text/javascript'
          // },
        ],
        title: metaTitle,
        meta: [
          { hid: 'description', name: 'description', content: this.seo.description },
          { hid: 'keywords', name: 'keywords', content: this.seo.keywords },
          { hid: 'og:title', property: 'og:title', content: metaTitle },
          { hid: 'og:description', property: 'og:description', content: this.seo.description },
          { hid: 'og:image', property: 'og:image', content: metaImage }
        ],
        link: [
          {
            rel: 'canonical',
            href: this.seo.canonical_url
          }
        ]
      }
    }
  }
}
