
import { mapState } from 'vuex'
import NeedHelpPopup from '~/components/UI/need-help-popup.vue'
export default {
  components: { NeedHelpPopup },
  async asyncData ({ route, $axios, app, $replaceSEO }) {
    const seo = await $axios.get('sitemaps/articles')
    const articleTypeQuery = route.query.type || ''
    const data = await $axios.$get('/articles?type=' + articleTypeQuery)
    const articles = data.data

    return {
      articles,
      seo: $replaceSEO(seo.data.data, null, null, null, null, null),
      articleTypeQuery
    }
  },
  data () {
    return {
      headTitle: 'บทความบรรจุภัณฑ์ การสร้างแบรนด์ การออกแบบ เข้าใจง่ายมีประโยชน์',
      headDes: 'บทความบรรจุภัณฑ์ การออกแบบ สร้างแบรนด์ และผลิตกล่องกระดาษ กล่องครีม กล่องอาหารเสริม กล่องไปรษณีย์ การขายออนไลน์ การส่งพัสดุ ส่งเดลิเวอรี่ เข้าใจง่ายมีประโยชน์',
      headKey: 'ผลิตกล่อง,กล่องกระดาษ,กล่องไปรษณีย์,กล่องลูกฟูก,กล่องสวยๆ,กล่องครีม,สติกเกอร์,สติกเกอร์ติดกล่องอาหาร,ออกแบบบรรจุภัณฑ์,โรงงานผลิตกล่อง,บรรจุภัณฑ์,กล่องส่งพัสดุ,กล่องอาหาร,หลอดครีม,ขวดพลาสติก,ถุงพลาสติก,พลาสติก,กล่องสวยๆ,ส่งเดลิเวอรี่,การส่งพัสดุ,การขายออนไลน์',
      ogTitle: '',
      ogDes: ''
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
  },
  methods: {
    viewArticle (itemId) {
      return { name: 'articles-id', params: { id: itemId } }
    },
    async reloadArticles () {
      const data = await this.$axios.$get('/articles?type=' + this.articleTypeQuery)
      this.articles = data.data
    }
  },
  head () {
    return {
      title: this.seo.title,
      meta: [
        { hid: 'description', name: 'description', content: this.seo.description },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ]
    }
  }
}
