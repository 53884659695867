
import { mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// import firebase from 'firebase/app'
// import 'firebase/auth'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
// import { auth } from '@/plugins/firebase.js'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      error_message: '',
      email: '',
      password: '',
      name: '',
      phone: '',
      accepted: 'false',
      rememberme: 'true',
      showPassword: false,
      authenType: 'resetpassword',
      cookieAccept: true
    }
  },
  mounted () {
    // console.log(this.$route.fullPath)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: 'Reset Password Page'
      },
      user: {
        customerID: '',
        customerEmail: ''
      }
    })
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    resetPassword () {
      const auth = getAuth()
      this.$refs.validateResetPassword.validate().then((isValid) => {
        if (isValid) {
          this.showLoading()
          /* ajax call if valid */
          const that = this
          sendPasswordResetEmail(auth, this.email)
            .then(function () {
              that.hideLoading()
              // Password reset email sent.
              that.authenType = 'resetpassword_result'
            })
            .catch(function (error) {
              that.hideLoading()
              that.error_message = error.message
              that.$refs['modal-authen-error'].show()
              // Error occurred. Inspect error.code.
            })
        } else {
        /* something else if not valid */
        }
      })
    }
  }
}
