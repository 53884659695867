

import { mapMutations, mapState, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { sendPasswordResetEmail } from 'firebase/auth'
import otp from '~/components/modal/otp.vue'
export default {
  // middleware: ['guest'],
  components: {
    ValidationObserver,
    // ValidationProvider,
    otp
  },
  data () {
    return {
      error_message: '',
      email: '',
      password: '',
      name: '',
      phone: '',
      accepted: 'false',
      rememberme: 'true',
      showPassword: false,
      authenType: 'signin',
      cookieAccept: true
    }
  },
  computed: {
    ...mapState(['userData', 'lastLoginFail', 'lastLoginChanel']),
    ...mapGetters({
      authUser: 'firebaseUser/getAuthUser'
    })
  },
  mounted () {
    // redirect to login via phone
    this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))

    this.setLastLoginFail(false)
    this.setLastLoginChannel('')
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: 'Login Page'
      },
      user: {
        customerID: '',
        customerEmail: ''
      }
    })

    if (this.$route.query.redirect) {
      localStorage.setItem('redirect_url', this.$route.query.redirect || '')
    } else {
      localStorage.removeItem('redirect_url')
      // const backUrl = localStorage.getItem('back_url')

      // console.log('mmmmm', backUrl, currentUser)
      // if (currentUser && backUrl) {
      //   console.log('backIU', backUrl)
      //   localStorage.removeItem('back_url')
      //   this.$router.push(backUrl)
      // //   this.$router.push('/')
      // }
    }

    const backUrl = localStorage.getItem('back_url')
    if (this.authUser) {
      if (backUrl) {
        localStorage.removeItem('back_url')

        this.$router.push(backUrl)
      } else {
        this.$router.push('/')
      }
    }

    // if (localStorage.getItem('start-login') === '1' && localStorage.getItem('start-login-with')) {
    //   this.showLoading()
    // }
    // if (localStorage.getItem('start-login') === '1') {
    //   this.showLoading()
    //   setTimeout(() => {
    //     this.hideLoading()
    //   }, 1000)
    // } else {
    //   this.hideLoading()
    // }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setLastLoginFail', 'setLastLoginChannel']),
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    signin () {
      if (this.cookieAccept) {
        this.$refs.validateSignin.validate().then((isValid) => {
          if (isValid) {
            localStorage.setItem('start-login', 1)
            localStorage.setItem('start-login-with', 'email')
            this.showLoading()

            this.$store.dispatch('authenticateUser', {
              isLogin: true,
              email: this.email,
              password: this.password,
              rememberme: this.rememberme,
              name: null,
              phone: null
            })

            this.$router.back()
          } else {
          /* something else if not valid */
          // console.log('invalid')
          }
        })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signInSMS () {
      if (this.cookieAccept) {
        this.$refs.otp.phone = ''
        this.$refs.otp.register = false
        this.$refs.otp.show = true
        this.$refs.otp.login = true
        this.$refs.otp.email = ''
        this.$refs.otp.header = 'เข้าสู่ระบบด้วยเบอร์โทรศัพท์'
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signInGoogle () {
      // console.log('sign in google', this.cookieAccept)
      if (this.cookieAccept) {
        this.showLoading()
        // localStorage.setItem('start-login', 1)
        localStorage.setItem('start-login-with', 'google')
        this.$store.dispatch('authenticateUserGoogle')
        // console.log('signin google success')
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signInFacebook () {
      if (this.cookieAccept) {
        this.showLoading()
        localStorage.setItem('start-login-with', 'facebook')
        this.$store.dispatch('authenticateUserFacebook')
        // console.log('signin facebook success')
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    changeAuthen (to) {
      this.$nextTick(() => {
        if (this.$refs.validateSignin) { this.$refs.validateSignin.reset() }
        if (this.$refs.validateResetPassword) { this.$refs.validateResetPassword.reset() }
        if (this.$refs.validateSignup) { this.$refs.validateSignup.reset() }
      })
      this.authenType = to
    },
    resetPassword () {
      this.$refs.validateResetPassword.validate().then((isValid) => {
        if (isValid) {
          this.showLoading()
          /* ajax call if valid */
          const that = this
          sendPasswordResetEmail(
            this.email)
            .then(function () {
              that.hideLoading()
              // Password reset email sent.
              that.authenType = 'resetpassword_result'
            })
            .catch(function (error) {
              that.hideLoading()
              that.error_message = error.message
              that.$refs['modal-authen-error'].show()
              // Error occurred. Inspect error.code.
            })
        } else {
        /* something else if not valid */
        }
      })
    }
  }
}
