
import { mapMutations } from 'vuex'
import { ValidationObserver } from 'vee-validate'// ValidationProvider
// import liff from '@line/liff'
// import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default {
  head() {
    return {
      script: [
        { src: 'https://static.line-scdn.net/liff/edge/2/sdk.js'}
      ]
    }
  },
  layout: 'line',
  components: {
    ValidationObserver
    // ValidationProvider
  },
  data () {
    return {
      error_message: '',
      otp: '',
      phone: '',
      otpOption: 'phone',
      verify: 1,
      email: '',
      code: '',
      isNotLineApp: false
    }
  },
  mounted () {
    const thisObject = this
    this.showLoading()
    window.onload = async function (e) {
      if (liff.getLineVersion() !== null) {
        await liff.init({ liffId: thisObject.$line().liffId })
          .then(() => {
            if (thisObject.isNotLineApp !== true) {
              if (liff.isLoggedIn()) {
                thisObject.hideLoading()
              } else {
                liff.login()
              }
            }
          })
          .catch((err) => {
          // Error happens during initialization
            // eslint-disable-next-line no-console
            console.log(err.code, err.message)
            thisObject.isNotLineApp = true
            thisObject.hideLoading()
          })
      } else {
        thisObject.isNotLineApp = true
        thisObject.hideLoading()
      }
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    isNumber (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async sendOTP () {
      this.showLoading()
      const result = await this.$axios.$post('liff-otp',
        {
          otpOption: this.otpOption,
          phone: this.phone,
          email: this.email
        }
      )
      this.hideLoading()
      if (result.status === true) {
        this.verify = 2
      } else if (result.status === false) {
        this.setAlert({
          show: true,
          message: result.message,
          header: 'แจ้งเตือน'
        })
      }
    },
    async verifyOTP () {
      const thisObject = this
      this.showLoading()
      if (liff.getLineVersion() !== null) {
        await liff.init({ liffId: thisObject.$line().liffId })
          .then(async () => {
            if (thisObject.isNotLineApp !== true) {
              if (liff.isLoggedIn()) {
                // const getProfile = await liff.getProfile().catch((err) => { console.log(err) })
                const result = await this.$axios.$post('liff-verify-otp',
                  {
                    phone: this.phone,
                    code: this.code,
                    email: this.email,
                    otpOption: this.otpOption,
                    token: liff.getIDToken()
                  }
                )
                if (result.status === true) {
                  this.$store.dispatch('authenticateSmsUser', result)
                  this.verify = 3
                  liff.closeWindow()
                } else if (result.status === false) {
                  this.setAlert({
                    show: true,
                    message: result.message,
                    header: 'แจ้งเตือน'
                  })
                  this.verify = 1
                } else { }
                thisObject.hideLoading()
              } else {
                liff.login()
              }
            }
          })
          .catch((err) => {
          // Error happens during initialization
            alert(err.code)
            alert(err.message)
            thisObject.isNotLineApp = true
            thisObject.hideLoading()
          })
      } else {
        thisObject.isNotLineApp = true
        thisObject.hideLoading()
      }

      this.hideLoading()
    },
    complete () {
      this.show = false
      this.showLoading()
      this.$router.push(this.localeLocation({ name: 'my-orders' }))
    }
  }
}
