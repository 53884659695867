
import { mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import axios from 'axios'
// import firebase from 'firebase/app'
// import 'firebase/auth'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
// import otp from '~/components/modal/otp.vue'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)

extend('checkPhone', async (value) => {
  const check = await axios.post(cfg.axios.apiBaseURL + 'check/phone', {
    phone: value
  })
  if (!check.data.found) {
    return true
  }
  return 'เบอร์ ' + value + ' ถูกสมัครใช้งานแล้ว'
})

extend('checkEmail', async (value) => {
  const check = await axios.post(cfg.axios.apiBaseURL + 'check/email', {
    email: value
  })
  if (!check.data.found) {
    return true
  }
  return 'อีเมล ' + value + ' ถูกสมัครใช้งานแล้ว'
})

export default {
  // middleware: ['check-auth'],
  components: {
    ValidationObserver,
    ValidationProvider
    // otp
  },
  data () {
    return {
      error_message: '',
      email: '',
      password: '',
      name: '',
      phone: '',
      accepted_1: 'false',
      accepted_2: 'false',
      accepted_3: 'false',
      rememberme: 'true',
      showPassword: false,
      authenType: 'signin',
      cookieAccept: true
    }
  },
  watch: {
    accepted_1 (checked) {
      // console.log('accepted_1', checked)
      if (checked) {
        this.accepted_2 = true
        this.accepted_3 = true
      }
    }
  },
  mounted () {
    // console.log(this.$route.fullPath)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: 'Register Page'
      },
      user: {
        customerID: '',
        customerEmail: ''
      }
    })
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert']),
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    signin () {
      if (this.cookieAccept) {
        this.$refs.validateSignin.validate().then((isValid) => {
          if (isValid) {
            this.showLoading()
            this.$store
              .dispatch('authenticateUser', {
                isLogin: true,
                email: this.email,
                password: this.password,
                rememberme: this.rememberme,
                name: null,
                phone: null
              })
              // .then((res) => {
              //   console.log('signin', res)
              //   this.hideLoading()
              //   if (res.status === 200) {
              //     this.email = ''
              //     this.password = ''
              //     this.$router.push({ name: 'index' })
              //   } else {
              //     this.error_message = res.message
              //   }
              // })
          } else {
            /* something else if not valid */
            // console.log('invalid')
          }
        })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    async signup () {
      await this.$refs.validateSignup.validate().then(async (isValid) => {
        if (isValid) {
          this.showLoading()
          localStorage.setItem('start-login', 1)
          localStorage.setItem('start-login-with', 'email')
          await this.$store
            .dispatch('authenticateUser', {
              isLogin: false,
              email: this.email,
              password: this.password,
              name: this.name,
              phone: this.phone,
              accepted: this.accepted_1,
              accepted_news: this.accepted_2,
              accepted_personal: this.accepted_3,
              rememberme: false
            })
            .then((res) => {
              this.showLoading()
              if (res && res.status === 200) {
                this.$refs.otp.phone = this.phone
                this.$refs.otp.register = true
                this.$refs.otp.show = true
                this.$refs.otp.email = this.email
                this.email = ''
                this.password = ''
                this.$axios.$post('give-points/new-customer-popup', { action_at: 'new-customer-popup' })
              } else {
                this.hideLoading()
                this.error_message = res.message
                this.$refs['modal-authen-error'].show()
              }
            })
        } else {
          /* something else if not valid */
          // console.log('invalid')
        }
      })
    },
    signInGoogle () {
      if (this.cookieAccept) {
        this.showLoading()
        this.$store.dispatch('authenticateUserGoogle').then((res) => {
          this.hideLoading()
          if (res.status === 200) {
            this.email = ''
            this.password = ''
            this.$router.push(this.localePath({ name: 'index' }))
            this.$axios.$post('give-points/new-customer-popup', { action_at: 'new-customer-popup' })
          } else {
            this.error_message = res.message
          }
        })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    signInFacebook () {
      if (this.cookieAccept) {
        this.showLoading()
        this.$store.dispatch('authenticateUserFacebook').then((res) => {
          this.hideLoading()
          if (res.status === 200) {
            this.email = ''
            this.password = ''
            this.$router.push(this.localePath({ name: 'index' }))
            this.$axios.$post('give-points/new-customer-popup', { action_at: 'new-customer-popup' })
          } else {
            this.error_message = res.message
          }
        })
      } else {
        this.setAlert({
          show: true,
          message: 'Please accept cookies policy before login.',
          header: 'แจ้งเตือน'
        })
      }
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    changeAuthen (to) {
      this.$nextTick(() => {
        if (this.$refs.validateSignin) {
          this.$refs.validateSignin.reset()
        }
        if (this.$refs.validateResetPassword) {
          this.$refs.validateResetPassword.reset()
        }
        if (this.$refs.validateSignup) {
          this.$refs.validateSignup.reset()
        }
      })
      this.authenType = to
    },
    resetPassword () {
      const auth = getAuth()
      this.$refs.validateResetPassword.validate().then((isValid) => {
        if (isValid) {
          this.showLoading()
          /* ajax call if valid */
          const that = this

          sendPasswordResetEmail(auth, this.email)
            .then(function () {
              that.hideLoading()
              // Password reset email sent.
              that.authenType = 'resetpassword_result'
            })
            .catch(function (error) {
              that.hideLoading()
              that.error_message = error.message
              that.$refs['modal-authen-error'].show()
              // Error occurred. Inspect error.code.
            })
        } else {
          /* something else if not valid */
        }
      })
    }
  }
}
