
import { mapMutations, mapState } from 'vuex'
export default {
  middleware: ['auth'],
  validate ({ params }) {
    // Must be a number
    return /^\d+$/.test(params.id)
  },
  async asyncData ({ params, $axios, $cookies }) {
    const result = await $axios.$get(`orders/${params.id}`)
    let resultMapping = null
    if (result.data && typeof result.data.products !== 'undefined') {
      resultMapping = await $axios.$get('mapping_inputs', {
        params: { mapping_pattern_id: result.data.products[0].mapping_input_id }
      })
    }

    return {
      orderId: params.id,
      orderCode: params.order_code,
      packagingTypeId: (resultMapping != null && resultMapping.data.mappingInput) ? resultMapping.data.mappingInput.packaging_type_id : resultMapping.data.packaging_type_id,
      mapping: params.mapping
    }
  },
  data () {
    return {
      brandName: null,
      logoFile: '',
      fileName: '',
      designDetail: null,
      brandCategory: null,
      orderId: 1,
      termAndConditionAgreement: false,
      isSending: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState(['userData']),
    brandNameCheck () {
      if (this.brandName === null) {
        return null
      }
      if (this.brandName) {
        return true
      } else {
        return false
      }
    },
    brandCategoryCheck () {
      if (this.brandCategory === null) {
        return null
      }
      if (this.brandCategory) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: this.$route.name,
        path: `${this.$route.path}`,
        title: 'brief-design'
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
    this.hideLoading()
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    saveBriefDetail () {
      // set stage to sending
      this.isSending = true

      // set data formate
      const params = {
        is_brief_design: true,
        graphic_status: 1,
        brd_brand_name: this.brandName,
        brd_brand_category: this.brandCategory,
        brd_brand_detail: this.designDetail,
        brd_file: this.logoFile,
        fileName: this.fileName
      }

      // post to API
      this.$axios.$post('order/' + this.orderId + '/save-brief-design', params).then((resp) => {
        // @TODO redirect to target
        this.$router.push(this.localeLocation({ name: 'checkout-id', params: { id: this.orderId, order_code: this.orderCode, mapping: this.mapping } }))
      }).catch((error) => {
        this.errorMessage = error
      })
    },
    async brownFile (file) {
      this.logoFile = await this.singleFileToBase64(file.target.files[0])
      this.fileName = file.target.files[0].name
    },
    singleFileToBase64 (files) {
      const reader = new FileReader()
      // read the file into a base64 format
      reader.readAsDataURL(files)

      // return result base 64
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort()
        }

        // return the base 64 string
        reader.onload = function () {
          resolve(reader.result)
        }
      })
    }
  }
}
